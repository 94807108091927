import { getEnvVar } from './ReactScriptHelper';

export const BUILD_COMMIT: string = getEnvVar('BUILD_COMMIT', 'develop');
export const AppId: string = getEnvVar('ID', '');
export const AppName: string | undefined = getEnvVar('NAME') ?? AppId;
export const EAIWS_NULL_ID: string = '00000000-0000-0000-0000-000000000000';
export const FEEDBACK_EMAIL: string = getEnvVar('FEEDBACK_EMAIL', '');
export const GITLAB_EMAIL: string = getEnvVar('GITLAB_EMAIL', '');
/** parameter value without trailing slash */
export const EAIWS_SERVER = getEnvVar('EAIWS_SERVER', '').replace(/\/*$/, '');
