import { parseQueryString } from './Url';
import { isNullOrEmpty, isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

// Note: we could use the nodejs-types but there are some issues
// with divergent types for common functions (e.g: window.setTimeout)
declare let process: {
    env: Record<string, string | undefined>;
};

// Note: we should use this `env` var instead of using `process.env` multiple times because
// otherwise the build js file will include the content of the `process.env` var multiple times
const env: Record<string, string | undefined> = process.env;

const REACT_APP_PREFIX: string = 'REACT_APP_';
const UndefinedEnvVars: Set<string> = new Set<string>();

function getBuildVar(key: string, fallback: string): string;
function getBuildVar(key: string, fallback?: string): string | undefined;
function getBuildVar(key: string, fallback?: string): string | undefined {
    const value: string | undefined = env[REACT_APP_PREFIX + key];

    if (value !== undefined) {
        return value;
    }

    UndefinedEnvVars.add(key);

    return fallback;
}

export const PUBLIC_URL: string = env.PUBLIC_URL ?? './';

// Note: at the moment the value is only generated once and not updated
// with a "hashchange" because I am not sure if this makes sense or not.
export const UrlVars = parseQueryString(window.location.hash.slice(1));

/**
 * This `set` includes all keys passed to the `getEnvVar` function.
 * Note: The values are added at runtime and therefore the content will vary
 * depending on which parts of the code has already been loaded and executed.
 */
export const RequestedEnvVars: ReadonlyMap<string, Function> = new Map();
function setEnvType(key: string, type: Function): void {
    if (!RequestedEnvVars.has(key)) {
        (RequestedEnvVars as Map<string, Function>).set(key, type);
    }
}
(window as {_RequestedEnvVars?: unknown})._RequestedEnvVars = RequestedEnvVars;

export function getEnvVar(key: string): string | undefined;
export function getEnvVar(key: string, fallback: string): string;
export function getEnvVar(key: string, fallback?: string): string | undefined {
    setEnvType(key, String);

    return _getEnvVar(key, fallback);
}

/**
 * Warning: should only be used in `getEnvVar` and to populate `allEnvVars`
 */
function _getEnvVar(key: string, fallback?: string): string | undefined {
    const value: string = UrlVars[key];

    if (value !== undefined) {
        return value;
    }

    return getBuildVar(key, fallback);
}

export function getEnvArray(key: string, delimiter: string = ','): Array<string> {
    setEnvType(key, Array);

    let value: string | undefined = UrlVars[key];

    if (value === undefined) {
        value = getBuildVar(key, '');
    }

    return value.split(delimiter).map((v: string): string => v.trim()).filter(isNotNullOrEmpty);
}

export function getEnvNumber(key: string): number | undefined;
export function getEnvNumber(key: string, fallback: number): number;
export function getEnvNumber(key: string, fallback?: number): number | undefined {
    setEnvType(key, Number);

    const value: string | undefined = getEnvVar(key);
    if (value === undefined) {
        return fallback;
    }

    return parseInt(value, 10);
}

export function getEnvBoolean(key: string, fallback: boolean = false): boolean {
    setEnvType(key, Boolean);

    const value: string | undefined = getEnvVar(key);
    if (isNullOrEmpty(value)) {
        return fallback;
    }

    switch (value.toLocaleLowerCase()) {
        case '0':
        case 'false':
            return false;
        default:
            return true;
    }
}

export function getEnvValue<T extends string>(key: string, allowed: Array<T>, fallback: T): T {
    const value: string = getEnvVar(key, fallback);
    if (allowed.includes(value as T)) {
        return value as T;
    }
    return fallback;
}

export const developmentMode: boolean = env.NODE_ENV === 'development';

// Only for debug purpose
export const allEnvVars: Record<string, string | undefined> = {};
const prefixLength: number = REACT_APP_PREFIX.length;
Object.keys(env).filter(
    (v: string): boolean => v.startsWith(REACT_APP_PREFIX)
).map(
    (v: string) => v.substr(prefixLength)
).forEach((v: string): void => {
    allEnvVars[v] = _getEnvVar(v, undefined);
});

function getUndefinedEnvVars(): Iterable<string> {
    return UndefinedEnvVars.values();
}

if (developmentMode) {
    (window as {_env_vars_?: {}})._env_vars_ = env;
    (window as {_getUndefinedEnvVars?: () => Iterable<string>})._getUndefinedEnvVars = getUndefinedEnvVars;
}
