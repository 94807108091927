import type { SessionResponse } from '@egr/gatekeeper-lib';
import type { User } from '@egr/xbox/pcon-login-api/User';
import { VersionLabel } from '@egr/xbox/utils/Version';

import { isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';
import { EdgeInsets } from './WebAction';

interface ApiVersion {
    major: number;
    minor: number;
}

interface AppVersion extends ApiVersion {
    patch: number;
    build?: number;
}

interface AppCaching {
    active: boolean;
    app_scheme: string;
    fs_scheme: string;
}

export interface DataSource {
    id: string;
    name: string;
    projectSettings?: Record<string, unknown>;
}

export interface AppInfo {
    api_version: Readonly<ApiVersion>;
    app_version: Readonly<AppVersion>;
    app_protocol: string;
    app_caching?: Readonly<AppCaching>;
    initialization_url: string;
    initialization_argument?: string;
    production_url: string;
    status_bar_height: number;
    insets: EdgeInsets;
    ar_device: boolean;
    localization_settings?: {
      country_code: string;
      currency_code: string;
      language_code: string;
    };
    ws_endpoint?: string;
    /**
     * indicates if the application supports light/dark/auto themes (assume false if this is missing)
     */
    supports_themes?: boolean;

    // introduced with the native app container
    session?: SessionResponse;
    hideBottomSpacer: boolean;
    openFile?: {
        path: string;
        type: string;
    };
    activeDataSource?: Readonly<DataSource>;
    availableDataSources?: ReadonlyArray<DataSource>;
    userInfo?: User;
    fs_root: string;
}

export let appInfo: Readonly<AppInfo> | undefined | null;

export function hasAppApiVersion(major: number, minor: number): boolean {
    if (appInfo != null) {
        if (appInfo.api_version.major > major) {
            return true;
        }

        return appInfo.api_version.major === major && appInfo.api_version.minor >= minor;
    }

    return false;
}

export function getLanguage(): string {
    if (appInfo?.localization_settings != null && isNotNullOrEmpty(appInfo.localization_settings.language_code)) {
        return appInfo.localization_settings.language_code;
    }

    if (navigator.languages != null && isNotNullOrEmpty(navigator.languages[0])) {
        return navigator.languages[0];
    }

    return navigator.language;
}

export function versionLabelInApp(): string {
    if (appInfo?.app_version != null) {
        return `${getNativeVersion()} (${VersionLabel})`;
    }
    return VersionLabel;
}

export function setAppInfo(value: Readonly<AppInfo> | undefined | null): Readonly<AppInfo> | undefined | null {
    appInfo = value;
    return value;
}

function getNativeVersion(): string {
    if (appInfo == null) {
        return '';
    }
    const appVersion: AppVersion = appInfo.app_version;
    if (appVersion.build == null) {
        return `${appVersion.major}.${appVersion.minor}.${appVersion.patch}`;
    }
    return `${appVersion.major}.${appVersion.minor}.${appVersion.patch}.${appVersion.build}`;
}