import { isNotNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';
import { _log } from './LogHandler';

export type UnregisterCallback = () => void;
type MessageHandlerSignature = (event: MessageEvent) => void;

const MESSAGE_HANDLERS: Map<string, Set<MessageHandlerSignature>> = new Map<string, Set<MessageHandlerSignature>>();

interface MessageHandler {
    type: string;
    callback: MessageHandlerSignature;
    singleUse?: boolean;
    allowMultipleHandlers?: boolean;
}

export function canAddHandler(type: string): boolean {
    return (MESSAGE_HANDLERS.get(type)?.size ?? 0) === 0;
}

export function registerHandler(handler: MessageHandler): UnregisterCallback {
    if ((MESSAGE_HANDLERS.get(handler.type)?.size ?? 0) > 0 && handler.allowMultipleHandlers !== true) {
        throw new Error('A message handler for the following type has already been registered: ' + handler.type);
    }

    const unregisterCallback: UnregisterCallback = (): void => {
        MESSAGE_HANDLERS.get(handler.type)!.delete(handler.callback);
    };

    if (!MESSAGE_HANDLERS.has(handler.type)) {
        MESSAGE_HANDLERS.set(handler.type, new Set());
    }

    if (handler.singleUse === true) {
        MESSAGE_HANDLERS.get(handler.type)!.add((event: MessageEvent): void => {
            unregisterCallback();
            handler.callback(event);
        });
    } else {
        MESSAGE_HANDLERS.get(handler.type)!.add(handler.callback);
    }

    return unregisterCallback;
}

function receiveMessage(event: MessageEvent): void {
    if (event.data != null && isNotNullOrEmpty(event.data.type)) {
        _log(event.data);

        const callbacks: Set<MessageHandlerSignature> | undefined = MESSAGE_HANDLERS.get(event.data.type);

        if (callbacks == null) {
            return;
        }

        for (const callback of callbacks) {
            callback(event);
        }
    }
}

window.addEventListener('message', receiveMessage, false);