import * as React from 'react';
import { createRoot } from 'react-dom/client';

import { AppLoader } from './AppLoader';

export async function renderApp(): Promise<void> {
    const rootElement = document.getElementById('root');

    if (rootElement == null) {
        throw new Error('root element not found');
    }

    const root = createRoot(rootElement);

    root.render(<AppLoader/>);
}