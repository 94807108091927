import * as React from 'react';
import i18next from 'i18next';
import { Theme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import {
    action,
    computed,
    IObservableValue,
    observable,
    makeObservable,
} from 'mobx';
import { observer } from 'mobx-react-lite';

import { android, mediaQueryMatch } from '@egr/xbox/utils/UserAgent';
import { appInfo } from '@egr/xbox/app-api/AppInfo';

import { ListenableEvent } from '@easterngraphics/wcf/modules/utils';

export type AppTheme = 'auto' | 'light' | 'dark';

const darkmodeMediaQuery: MediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');

interface MuiThemeForDarkmodeProps {
    darkTheme: Theme;
    lightTheme: Theme;
}

class LayoutHandler {
    public readonly darkModeObservable: IObservableValue<boolean> = observable.box<boolean>(mediaQueryMatch('(prefers-color-scheme: dark)'));

    public readonly layoutModeObservable: IObservableValue<AppTheme> = observable.box<AppTheme>('auto');

    constructor() {
        makeObservable(this);
        this.triggerDarmodeChange = this.triggerDarmodeChange.bind(this);
        this.layoutModeObservable.observe_(this.triggerDarmodeChange);
        this.darkModeObservable.observe_(this.triggerDarmodeChange);

        try {
            // Chrome & Firefox
            darkmodeMediaQuery.addEventListener('change', (event: MediaQueryListEvent) => {
                this.setDarkmode(event.matches);
            });
        } catch {
            try {
                // Safari
                darkmodeMediaQuery.addListener((event: MediaQueryListEvent) => {
                    this.setDarkmode(event.matches);
                });
            } catch {
                /* */
            }
        }
    }

    /** event-value is true if darkmode is active  */
    public onDarkmodeChange: ListenableEvent<boolean, LayoutHandler> = new ListenableEvent<boolean, LayoutHandler>(this);

    @computed
    public get isDarkmodeActive(): boolean {
        switch (this.layoutModeObservable.get()) {
            case 'auto':
                return this.darkModeObservable.get();
            default:
                return this.layoutModeObservable.get() === 'dark';
        }
    }

    @computed
    public get layoutMode(): AppTheme {
        return this.layoutModeObservable.get();
    }

    @action
    public updateLayoutMode(value: AppTheme): void {
        this.layoutModeObservable.set(value);
    }

    private triggerDarmodeChange(): void {
        this.onDarkmodeChange.trigger(this.isDarkmodeActive);
    }

    @action
    private setDarkmode(value: boolean) {
        this.darkModeObservable.set(value);
    }
}

export const layoutInformation: LayoutHandler = new LayoutHandler();

export const MuiThemeForDarkmode = observer(
    (props: React.PropsWithChildren<MuiThemeForDarkmodeProps>) => {
        return (
            <StyledEngineProvider injectFirst={true}>
                <ThemeProvider theme={layoutInformation.isDarkmodeActive ? props.darkTheme : props.lightTheme}>
                    {props.children}
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
);

export function layoutModeToLabel(layout: AppTheme | undefined): string {
    switch (layout) {
        case 'dark': return i18next.t<string>('Dark');
        case 'light': return i18next.t<string>('Light');
        default: return i18next.t<string>('Automatic');
    }
}

export function validLayout(layout: AppTheme | undefined): AppTheme {
    switch (layout) {
        case 'dark': return 'dark';
        case 'light': return 'light';
        default: return (android || !(appInfo?.supports_themes)) ? 'light' : 'auto';
    }
}